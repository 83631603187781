
























































import {Component, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {Commit} from '@/model/resource/Commit'
import moment from 'moment'

@Component({})
export default class CaCommitCard extends MixinScreenSize {
  @Prop({type: Commit, required: true}) commit!: Commit

  get repositoryColor() {
    return `background-color: #${this.commit.repository?.organization?.colorHex}`
  }

  formattedDateTime() {
    const date = this.commit.datetime
    return moment(date).fromNow()
  }

  formattedCommitUrl(url: string) {
    return url
      .replace('api.github.com/repos', 'github.com')
      .replace('commits', 'commit')
  }
}

















import {Component, Prop} from 'vue-property-decorator'
import ECharts from 'vue-echarts/components/ECharts.vue'
import 'echarts/lib/chart/line'
import 'echarts/lib/component/tooltip'
import _ from 'lodash'
import moment, {Moment} from 'moment'
import {CAController, StatsTypeEnum} from '@/app/CAController'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {capitalize} from '@brazilian-utils/brazilian-utils'

export class Serie {
  dataIndex = 0
  seriesIndex = 0
  type: string | null = null
  seriesName = ''
  stack: string | null = null
  lineStyle: any | null = null
  itemStyle: any | null = null
  emphasis: any | null = null
  showSymbol: boolean | null = false
  areaStyle: any | null = null
  data: number[] | null = []
  name: string | null = null
  colorHex: string | null = null
}

@Component({
  components: {ECharts},
})
export default class CaStackedAreaChart extends MixinScreenSize {
  @Prop({type: CAController, required: true}) controller!: CAController

  monthWithLegend: number[] = []

  get range() {
    return this.controller.range
  }

  get xAxisData(): Moment[] {
    const initialDate = moment().subtract(this.range, 'days')
    // (this.range) because we will show the current day on the graph
    return Array.from({length: this.range}).map(() => {
      const nextDate = initialDate.add(1, 'day')
      return _.cloneDeep(nextDate)
    })
  }

  formatterTooltip(params: Serie[]) {
    const [{dataIndex}] = params
    const date = this.xAxisData[dataIndex]
    const formattedDate = moment(date).format(
      this.$t('components.CAStackedAreaChart.tooltipDataFormat') as string
    )

    const base = `
                  <div class="verti w-60">
                      <div class="horiz mx-2 text-white-500 justify-between">
                        <span class="mr-4">
                             ${formattedDate}
                        </span>
                        <span>Actions</span>
                      </div>
`

    let body = ``
    const parsedSerie = this.parsedSerie()
    params.forEach(param => {
      //@ts-ignore
      const actions = parsedSerie[param.seriesIndex].data[param.dataIndex]
      const color = parsedSerie[param.seriesIndex].colorHex

      if (actions <= 0) {
        return
      }

      body += `
              <div class="horiz mx-2 text-white justify-between">
                <div class="horiz">
                  <div
                    class="h-3 w-3 rounded-full mr-3 mt-2"
                    style="background-color: #${color}"
                  >&nbsp;</div>
                  <span>${this.formatTitle(param.seriesName)}</span>
                </div>
                <span class="text-right">${actions}</span>
              </div>
`
    })

    const footer = `</div>`

    return base + body + footer
  }

  formatTitle(value: string) {
    return this.$filter.truncate(value ?? '', 10)
  }

  get gridConfig() {
    return {
      top: '2%',
      left: '0%',
      right: '0%',
      bottom: '0%',
      width: this.isMobile ? '100%' : '99%',
      containLabel: true,
    }
  }

  get parsedData() {
    const isFilteredByCommit =
      this.controller.statsType?.$id === StatsTypeEnum.COMMIT

    const {commits, lines} = this.controller.stackedChartStats

    return isFilteredByCommit ? commits : lines
  }

  parsedSerie() {
    return this.controller.selectedActivityVolume.map(item => {
      const data = this.parsedData.get(item.$id.toString(10))

      return {
        name: item.$tag,
        type: 'line',
        stack: 'dataChart',
        lineStyle: {
          width: 0,
        },
        itemStyle: {
          color: `#${item.colorHex}`,
        },
        showSymbol: false,
        emphasis: {
          itemStyle: {
            color: `#${item.colorHex}`,
          },
        },
        areaStyle: {},
        data,
        colorHex: item.colorHex,
      }
    })
  }

  get options() {
    return {
      tooltip: {
        trigger: 'axis',
        formatter: (params: any) => this.formatterTooltip(params),
        position: 'top',
        oder: 'valueDesc',
        confine: true,
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: this.xAxisData.map(item =>
          item.format(
            this.$t('components.CAStackedAreaChart.monthDayFormat') as string
          )
        ),
        axisLabel: {
          interval:
            this.range > 20 ? (this.isDesktop ? 3 : 8) : this.isDesktop ? 0 : 1,
          showMaxLabel: true,
          margin: 12,
          textStyle: {
            fontWeight: '800',
            color: '#82919c',
          },
        },
        axisTick: {
          show: false,
        },
      },
      yAxis: [
        {
          type: 'value',
          axisLine: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              fontWeight: '800',
              color: '#82919c',
            },
            formatter: (value: any) => {
              if (value == 0) return ''
              else if (value >= 1000) return `${value / 1000}k`
              else return value
            },
          },
        },
        {
          //Creates a y axis on the right side of the graph to add a small margin, so the last label won't be cut
          axisLine: {
            show: false,
          },
          axisLabel: {
            margin: this.isDesktop ? 10 : 20,
          },
          position: 'right',
          data: [''],
        },
      ],
      grid: this.gridConfig,
      series: this.parsedSerie(),
    }
  }
}

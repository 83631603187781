





















































































import {Modal} from '@simpli/vue-modal'
import {Component, Emit, Mixins, Prop} from 'vue-property-decorator'
import {InputCheckbox, InputText} from '@simpli/vue-input'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {CAController, CAType} from '@/app/CAController'

@Component({
  components: {Modal, InputCheckbox, InputText},
})
export default class CaOverviewMobileFilter extends Mixins(MixinScreenSize) {
  @Prop({type: CAController, required: true}) controller!: CAController

  private selectedOrganizationIndexes: number[] = []
  private selectedRepositoriesIndexes: number[] = []
  private statsType = this.controller.statsTypeOptions[0]

  get hasOrganizationSelected() {
    return !!this.controller.selectedOrganization
  }

  created() {
    this.statsType = this.controller.statsType
    this.selectedOrganizationIndexes = this.controller.selectedActivityVolume
      .filter(it => it.caType === CAType.ORGANIZATION)
      .map((it, index) => index)

    const selectedRepositories = this.controller.selectedRepositoryIndex
      ? [this.controller.selectedRepositoryIndex]
      : []

    this.selectedRepositoriesIndexes = this.controller.selectedRepository
      ? selectedRepositories
      : this.controller.selectedActivityVolume
          .filter(it => it.caType === CAType.REPOSITORY)
          .map((it, index) => index)
  }

  mounted() {
    document.addEventListener('scroll', this.onScroll)
  }

  beforeDestroy() {
    document.removeEventListener('scroll', this.onScroll)
  }

  onScroll() {
    window.scrollTo(0, 0)
  }

  doClear() {
    this.controller.deselectedActivityVolume()
    this.toggleModal()
  }

  selectOrganization(index: number) {
    if (this.selectedOrganizationIndexes.includes(index)) {
      const foundIndex = this.selectedOrganizationIndexes.findIndex(
        it => it === index
      )
      this.selectedOrganizationIndexes.splice(foundIndex, 1)
      return
    }
    this.selectedOrganizationIndexes.push(index)
  }

  selectRepository(index: number) {
    if (this.selectedRepositoriesIndexes.includes(index)) {
      const foundIndex = this.selectedRepositoriesIndexes.findIndex(
        it => it === index
      )
      this.selectedRepositoriesIndexes.splice(foundIndex, 1)
      return
    }
    this.selectedRepositoriesIndexes.push(index)
  }

  doFilter() {
    this.controller.deselectedActivityVolume()
    this.selectedOrganizationIndexes.forEach(it =>
      this.controller.selectToActivityVolumeIndex(it)
    )
    this.selectedRepositoriesIndexes.forEach(it =>
      this.controller.selectToActivityVolumeIndex(it)
    )
    this.controller.statsType = this.statsType
    this.toggleModal()
  }

  @Emit('toggleModal')
  private toggleModal(): void {}
}

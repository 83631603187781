





























































import {Component, Mixins, Prop} from 'vue-property-decorator'
import {Modal} from '@simpli/vue-modal'
import CaVolumeMobileFilter from '@/views/ca-volume/CaVolumeMobileFilter.vue'
import {CAController} from '@/app/CAController'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import CaStackedAreaChart from '@/components/ca/CaStackedAreaChart.vue'
import CaChecker from '@/components/ca/CaChecker.vue'
import {InputSelect} from '@simpli/vue-input'
import CaVolumeFilter from '@/components/ca/CaVolumeFilter.vue'

@Component({
  components: {
    CaVolumeFilter,
    CaVolumeMobileFilter,
    CaChecker,
    CaStackedAreaChart,
    Modal,
    InputSelect,
  },
})
export default class CaVolume extends Mixins(MixinScreenSize) {
  @Prop({type: CAController, required: true}) controller!: CAController

  private showModal: boolean = false

  private toggleModal(): void {
    this.showModal = !this.showModal
  }
}

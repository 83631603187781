





































































































































































































import {Component, Mixins, Prop} from 'vue-property-decorator'
import moment from 'moment'
import ECharts from 'vue-echarts/components/ECharts.vue'
import {CAController, CAType} from '@/app/CAController'
import {Organization} from '@/model/resource/Organization'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'

@Component({
  components: {ECharts},
})
export default class CaWheel extends Mixins(MixinScreenSize) {
  @Prop({type: CAController, required: true}) controller!: CAController

  get iterator() {
    return this.controller.selectedIterator
  }

  get repositoryGithubUrl() {
    return (
      this.controller.selectedRepository?.repositoryUrl ||
      this.controller.selectedOrganization?.repositoryUrl
    )
  }

  get lastUpdateAt() {
    const moments = this.controller.allOrganizations
      .map(it => moment(it.lastUpdateAt))
      .filter(it => it.isValid())

    const result = moment.max(moments)
    return result.isValid() ? result : null
  }

  get allSelectedOrganizations(): Organization[] {
    if (this.controller.selectedOrganization !== null) {
      return [this.controller.selectedOrganization]
    }
    return this.controller.allOrganizations
  }

  get dataForOrganizations() {
    return this.allSelectedOrganizations?.map(it => ({
      name: it.$tag,
      value: it.activitiesCount,
      id: it.$id,
      caType: it.caType,
    }))
  }

  get dataForOrganizationsPalette() {
    return this.allSelectedOrganizations?.map(it => `#${it.colorHex}`)
  }

  get dataForRepositories() {
    return this.controller.allRepositoryFromSelectedOrganization?.map(it => ({
      name: it.$tag,
      value: it.activitiesCount,
      id: it.$id,
      caType: it.caType,
    }))
  }

  get dataForRepositoriesPalette() {
    return this.controller.allRepositoryFromSelectedOrganization?.map(
      it => `#${it.colorHex}`
    )
  }

  get options() {
    return {
      series: [
        {
          name: this.$t('components.caWheel.organizationActivities'),
          type: 'pie',
          radius: this.dataForOrganizations.length
            ? ['90%', '95%']
            : ['85%', '90%'],
          data: this.dataForOrganizations,
          color: this.dataForOrganizationsPalette,
          label: {
            show: false,
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
        {
          name: this.$t('components.caWheel.repositoryActivities'),
          type: 'pie',
          radius: ['85%', '91%'],
          data: this.dataForRepositories,
          color: this.dataForRepositoriesPalette,
          label: {
            show: false,
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
      silent: !this.isDesktop,
      tooltip: {
        formatter: (params: {
          seriesName: string
          name: string
          data: {value: number}
        }) => {
          return this.isDesktop
            ? `<div>${params.seriesName}</div>${
                params.name
              }: ${params.data.value.toLocaleString('eng-us')}`
            : ''
        },
        trigger: 'item',
      },
    }
  }

  onChartClick(event: {data: {caType: number; id: number}}) {
    if (this.isDesktop) {
      const {
        data: {caType, id},
      } = event

      if (caType === CAType.ORGANIZATION) {
        const index = this.controller.allOrganizations.findIndex(
          it => it.$id === id
        )
        this.controller.selectOrganization(index)
        return
      }

      const index = this.controller.allRepositoryFromSelectedOrganization.findIndex(
        it => it.$id === id
      )
      this.controller.selectRepository(index)
    }
  }
}












































































import {Component, Prop} from 'vue-property-decorator'
import CaCommitCard from '@/components/ca/CaCommitCard.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {CAController} from '@/app/CAController'
import {$} from '@/facade'

@Component({
  components: {CaCommitCard},
})
export default class CaCommits extends MixinScreenSize {
  @Prop({type: CAController, required: true}) controller!: CAController

  async created() {
    await this.controller.lastCommitsCollection.queryToFirstPage()
    await this.$nextTick()
    this.controller.lastCommitsCollection.perPage = 6 // adap-swiper changes my perPage
  }

  formattedSubtitle() {
    const selectedRepository = this.controller.selectedRepository
    const selectedOrganization = this.controller.selectedOrganization

    if (selectedRepository) {
      return selectedRepository.title
    } else {
      if (selectedOrganization) {
        return selectedOrganization.title
      } else {
        return $.t('components.CaCommits.allOrganizations')
      }
    }
  }

  async expandLastCommits() {
    await this.controller.expandLastCommits()
  }
}

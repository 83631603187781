

































































import {Component, Prop, Vue} from 'vue-property-decorator'
import {InputCheckbox} from '@simpli/vue-input'
import {CAController, CAIterable, CAType} from '@/app/CAController'
import {capitalize} from '@brazilian-utils/brazilian-utils'

@Component({
  components: {InputCheckbox},
})
export default class CaChecker extends Vue {
  @Prop({type: CAController, required: true}) controller!: CAController

  maxActivityVolumes = 10
  lockWatcher = false

  get allActivityVolume() {
    if (this.controller.selectedOrganization) {
      return this.controller.allActivityVolume.slice(0, this.maxActivityVolumes)
    } else {
      return this.controller.allActivityVolume
    }
  }

  async backToAllOrganizations() {
    await this.controller.deselectOrganizations()
  }

  iterableColor(value: CAIterable) {
    return `background-color: #${value.colorHex}`
  }
}

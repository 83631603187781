












































import {Component, Mixins, Prop, Vue} from 'vue-property-decorator'
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import {CAController} from '@/app/CAController'
import CaLineCount from '../../components/ca/CaLineCount.vue'
import CaCommitCount from '../../components/ca/CaCommitCount.vue'
import CaStarCount from '../../components/ca/CaStarCount.vue'
import CaContributorCount from '../../components/ca/CaContributorCount.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'

@Component({
  components: {
    CaContributorCount,
    CaStarCount,
    CaCommitCount,
    CaLineCount,
    Swiper,
    SwiperSlide,
  },
})
export default class CaOverviewSliderMobile extends Mixins(MixinScreenSize) {
  @Prop({type: CAController, required: true}) controller!: CAController
}

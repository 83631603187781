






























import {Component, Prop, Vue} from 'vue-property-decorator'

@Component({})
export default class CaRepositorytInfo extends Vue {
  @Prop({type: Boolean, default: false}) isBoxVersion!: boolean
  @Prop({type: String, required: false}) description!: String
  @Prop({type: String, required: true}) projectUrl!: String

  adjustStringToHtml(val: String): String {
    const desc = val ? val : ''
    return desc.replaceAll('\n', '<br>')
  }
}

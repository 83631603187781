var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isBoxVersion)?_c('div',{staticClass:"top-languages-card-mobile"},[_c('img',{staticClass:"h-6 w-6",attrs:{"src":require("@/assets/img/icon-language.svg"),"alt":"logo"}}),_c('div',{staticClass:"text-left ml-7 mr-5 mb-3"},[_c('div',{staticClass:"text-sm mt-1"},[_vm._v(" "+_vm._s(_vm.$t('components.topLanguagesCard.title'))+" ")]),_c('div',{staticClass:"font-medium text-2xl"},[_c('ul',{staticClass:"list-disc mt-2 grid grid-cols-2 grid-rows-3"},_vm._l((_vm.value),function(item,i){return _c('div',{key:i},[_c('li',{staticClass:"mb-1 pl-2 ml-7"},[_c('span',{staticClass:"text-base -ml-3",domProps:{"innerHTML":_vm._s(
                _vm.$t('components.topLanguagesCard.languageAndPercent', {
                  title: item.name,
                  percent: _vm.$filter.decimal(item.ratio * 100, 1, 1),
                })
              )}})])])}),0)])])]):_c('div',{staticClass:"top-languages-card"},[_c('div',{staticClass:"horiz items-center"},[_c('img',{staticClass:"h-6 w-6",attrs:{"src":require("@/assets/img/icon-language.svg"),"alt":"logo"}}),_c('div',{staticClass:"font-normal ml-1 text-xl"},[_vm._v(" "+_vm._s(_vm.$t('components.topLanguagesCard.title'))+" ")])]),_c('div',{staticClass:"mt-2 text-left"},[_c('ul',{staticClass:"list-disc"},_vm._l((_vm.value),function(item,i){return _c('div',{key:i},[_c('li',{staticClass:"mb-1 text-xl pl-2 ml-7"},[_c('span',{staticClass:"text-lg -ml-3",domProps:{"innerHTML":_vm._s(
              _vm.$t('components.topLanguagesCard.languageAndPercent', {
                title: item.name,
                percent: _vm.$filter.decimal(item.ratio * 100, 1, 1),
              })
            )}})])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }
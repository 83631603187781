



















import {Component, Prop, Vue} from 'vue-property-decorator'
import {CAController, PeriodDaysEnum} from '@/app/CAController'
import {InputSelect} from '@simpli/vue-input'
import {IResource} from '@simpli/resource-collection'

@Component({
  components: {InputSelect},
})
export default class CaVolumeFilter extends Vue {
  @Prop({type: CAController, required: true}) controller!: CAController
  @Prop({type: Boolean, default: false}) hideStatsType!: Boolean

  statsPeriod: IResource = this.controller.statsPeriod

  async onChangePeriod() {
    let range = PeriodDaysEnum.SEVEN

    if (this.statsPeriod?.$id === 2) {
      range = PeriodDaysEnum.THIRTY
    }

    await this.controller.populateCharts(range)

    this.controller.statsPeriod = this.statsPeriod
    this.controller.range = range
  }
}








































import {Component, Vue} from 'vue-property-decorator'
import NavbarMenu from '@/components/NavbarMenu.vue'
import AppFooter from '@/components/AppFooter.vue'
import CaWheel from '@/components/ca/CaWheel.vue'
import {CAController} from '@/app/CAController'
import CaChecker from '@/components/ca/CaChecker.vue'
import CaCommitCard from '@/components/ca/CaCommitCard.vue'
import CaCommitCount from '@/components/ca/CaCommitCount.vue'
import CaContributorCount from '@/components/ca/CaContributorCount.vue'
import CaLineCount from '@/components/ca/CaLineCount.vue'
import CaProjects from '@/components/ca/CaProjects.vue'
import CaStarCount from '@/components/ca/CaStarCount.vue'
import CaTopLanguages from '@/components/ca/CaTopLanguages.vue'
import CaStackedAreaChart from '@/components/ca/CaStackedAreaChart.vue'
import CaNav from '@/components/ca/CaNav.vue'
import CaCommits from '@/components/ca/CaCommits.vue'
import CaOverview from '@/views/CaOverview.vue'
import CaVolume from '@/views/CaVolume.vue'
import {MetaInfo} from 'vue-meta'

@Component({
  components: {
    CaVolume,
    CaOverview,
    CaStackedAreaChart,
    CaNav,
    CaCommits,
    CaTopLanguages,
    CaStarCount,
    CaProjects,
    CaLineCount,
    CaContributorCount,
    CaCommitCount,
    CaCommitCard,
    CaChecker,
    CaWheel,
    NavbarMenu,
    AppFooter,
  },
})
export default class CaView extends Vue {
  controller = new CAController()

  metaInfo(): MetaInfo {
    return {
      title: this.$translate('metadata.title.communityDevelopment'),
    }
  }

  async created() {
    await this.$await.run('caLoading', () => this.controller.init())
  }
}

















































import {Component, Emit, Prop, Vue} from 'vue-property-decorator'
import {CAController} from '@/app/CAController'

@Component({})
export default class CaOverviewTitle extends Vue {
  @Prop({type: Boolean, required: true}) isMobile!: boolean
  @Prop({type: CAController, required: true}) controller!: CAController

  @Emit('toggleModal')
  private toggleModal(): void {}
}

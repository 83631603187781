








































































import {Modal} from '@simpli/vue-modal'
import {Component, Emit, Mixins, Prop} from 'vue-property-decorator'
import {$} from '@/facade'
import {InputCheckbox, InputText} from '@simpli/vue-input'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {CAController} from '@/app/CAController'
import {Organization} from '@/model/resource/Organization'
import {Repository} from '@/model/resource/Repository'

@Component({
  components: {Modal, InputCheckbox, InputText},
})
export default class CaOverviewMobileFilter extends Mixins(MixinScreenSize) {
  @Prop({type: CAController, required: true}) controller!: CAController

  private selectedOrganizationIndex: number = 0
  private selectedRepositoryIndex: number = 0

  created() {
    this.selectedOrganizationIndex =
      (this.controller.selectedOrganizationIndex ?? -1) + 1

    this.selectedRepositoryIndex =
      (this.controller.selectedRepositoryIndex ?? -1) + 1
  }

  mounted() {
    document.addEventListener('scroll', this.onScroll)
  }

  beforeDestroy() {
    document.removeEventListener('scroll', this.onScroll)
  }

  onScroll() {
    window.scrollTo(0, 0)
  }

  private get allOrganizations() {
    const all = new Organization()
    all.title = $.t('components.CaNav.mobile.all') as string

    return [all, ...this.controller.allOrganizations]
  }

  private get allRepositories() {
    const all = new Repository()
    all.title = $.t('components.CaNav.mobile.all') as string

    return [
      all,
      ...(this.controller.allOrganizations[this.selectedOrganizationIndex - 1]
        ?.repositories || []),
    ]
  }

  selectOrganization(index: number) {
    this.selectedOrganizationIndex = index
    this.selectedRepositoryIndex = 0
  }

  selectRepository(index: number) {
    this.selectedRepositoryIndex = index
  }

  doClear() {
    this.controller.deselectOrganizations()
    this.controller.deselectRepositories()
    this.toggleModal()
  }

  doFilter() {
    this.controller.deselectOrganizations()
    this.controller.deselectRepositories()
    this.controller.selectOrganization(this.selectedOrganizationIndex - 1)
    this.controller.selectRepository(this.selectedRepositoryIndex - 1)
    this.toggleModal()
  }

  @Emit('toggleModal')
  private toggleModal(): void {}
}

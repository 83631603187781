










































































import {Component, Prop} from 'vue-property-decorator'
import CaProjectCard from '@/components/ca/CaProjectCard.vue'
import {CAController} from '@/app/CAController'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {$} from '@/facade'

@Component({
  components: {CaProjectCard},
})
export default class CaProjects extends MixinScreenSize {
  @Prop({type: CAController, required: true}) controller!: CAController

  async created() {
    await this.controller.lastActiveProjectsCollection.queryToFirstPage()
    await this.$nextTick()
    this.controller.lastActiveProjectsCollection.perPage = 6 // adap-swiper changes my perPage
  }

  formattedSubtitle() {
    const selectedRepository = this.controller.selectedRepository
    const selectedOrganization = this.controller.selectedOrganization

    if (selectedRepository) {
      return selectedRepository.title
    } else {
      if (selectedOrganization) {
        return selectedOrganization.title
      } else {
        return $.t('components.CAProjects.allOrganizations')
      }
    }
  }

  async expandCollection() {
    await this.controller.expandLastActiveProjects()
  }
}

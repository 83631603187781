






















































































import {Component, Mixins, Prop} from 'vue-property-decorator'
import {RepositoryCache} from '@/model/resource/RepositoryCache'
import moment from 'moment'
import {Repository} from '@/model/resource/Repository'
import {Organization} from '@/model/resource/Organization'
import {capitalize} from '@brazilian-utils/brazilian-utils'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'

@Component
export default class CaProjectCard extends Mixins(MixinScreenSize) {
  @Prop({type: Repository, required: true})
  repository!: Repository
  minimumDaysToFormat = 86400000 // 1 day in milliseconds

  get repositoryCache() {
    return this.repository.repositoryCache ?? new RepositoryCache()
  }

  get languageTitle() {
    const repositoryLanguageList = this.repository.repositoryLanguage
    if (repositoryLanguageList?.length) {
      return repositoryLanguageList[0].language
    }
    return ''
  }

  formatedTokenColor(value: number) {
    value += 5
    if (value >= 10) value = 9
    return `bg-black-${value}00`
  }

  get formatedLabels() {
    return this.repository?.labelsTokenized?.split(',')
  }

  get formatColor() {
    return `background-color: #${this.repository.organization?.colorHex}`
  }

  formatTitle(value: Repository | Organization) {
    if (!value) return ''
    return capitalize(value.title?.replace('-', ' ') ?? '')
  }

  openRepository(value: string) {
    window.open(value, '_blank')
  }

  get formatLastCommit() {
    let duration = moment(this.repositoryCache.lastCommitAt).fromNow()
    return this.$t('components.CAProjectCard.lastUpdate', {time: duration})
  }
}
